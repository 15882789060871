<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12 pt-0 pb-2">
        <h1>Search Personnel</h1>
      </v-col>
    </v-row>
    <v-form @submit.prevent="searchPersonnel">
      <v-row>
        <v-col>
          <v-text-field
            v-model="search.firstName"
            label="First name">
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="search.lastName"
            label="Last name">
          </v-text-field>
        </v-col>
        <v-col>
          <v-checkbox
              v-model="search.archived"
              label="Search archived?"
              hide-details
            ></v-checkbox>
        </v-col>
        <v-col>
          <v-btn type="submit" small>Search</v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-row>
      <v-col>
        <v-simple-table
          fixed-header
          height="450px"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Name</th>
                <th class="text-left">Is crew member?</th>
                <th class="text-left">Is archived?</th>
                <th class="text-left">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(person, index) in personnel"
                :key="person.id"
              >
                <td>{{ person.name }}</td>
                <td>
                  <span v-if="person.isCrewMember">Yes</span>
                  <span v-else>No</span>
                </td>
                <td>
                  <span v-if="person.active">No</span>
                  <span v-else>Yes</span>
                </td>
                <td>
                  <router-link custom v-slot="{ navigate }" :to="`/admin/personnel-view/${person.id}`">
                    <v-btn
                      x-small
                      @click="navigate"
                      @keypress.enter="navigate"
                      role="link">
                      View
                    </v-btn>
                  </router-link>
                  &nbsp;
                  <router-link custom v-slot="{ navigate }" :to="`/admin/personnel-edit/${person.id}`">
                    <v-btn
                      x-small
                      @click="navigate"
                      @keypress.enter="navigate"
                      role="link">
                      Edit
                    </v-btn>
                  </router-link>
                  &nbsp;
                  <v-btn
                    v-if="person.active"
                    x-small
                    @click="archivePerson(index)">
                    Archive
                  </v-btn>
                  <v-btn
                    v-else
                    x-small
                    @click="unArchivePerson(index)">
                    Un-archive
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-dialog
      v-model="warnArchive"
      max-width="290"
    >
      <v-card>
        <v-card-text class="pt-4">
          Are you sure you want to archive this person?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="confirmArchive"
          >
            Confirm
          </v-btn>
          <v-btn
            text
            @click="warnArchive = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="warnUnArchive"
      max-width="290"
    >
      <v-card>
        <v-card-text class="pt-4">
          Are you sure you want to un-archive this person?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="confirmUnArchive"
          >
            Confirm
          </v-btn>
          <v-btn
            text
            @click="warnUnArchive = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from '../../../axios';

export default {
  name: 'AdminPersonnelIndex',
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      personnel: [],
      search: {
        archived: false,
        firstName: '',
        lastName: '',
      },
      warnArchive: false,
      toArchive: -1,
      warnUnArchive: false,
      toUnArchive: -1,
    };
  },
  methods: {
    unArchivePerson(index) {
      this.toUnArchive = index;
      this.warnUnArchive = true;
    },
    confirmUnArchive() {
      const personId = this.personnel[this.toUnArchive].id;
      axios.get(`/personnel/unArchive/${personId}.json?token=${this.token}`)
        .then(() => {
          this.personnel.splice(this.toArchive, 1);
          this.warnUnArchive = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    archivePerson(index) {
      this.toArchive = index;
      this.warnArchive = true;
    },
    confirmArchive() {
      const personId = this.personnel[this.toArchive].id;
      axios.get(`/personnel/archive/${personId}.json?token=${this.token}`)
        .then(() => {
          this.personnel.splice(this.toArchive, 1);
          this.warnArchive = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    searchPersonnel() {
      axios.post(`/personnel/search.json?token=${this.token}`, this.search)
        .then((response) => {
          this.personnel = response.data.personnel;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
